/* eslint-disable react/jsx-filename-extension */
import React from "react";
import BrandIcon from "parts/BrandIcon";
import Button from "elements/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import twitterXlogo from "../assets/images/twitter-x.webp";

export default function Footer() {
  return (
    <div className='flex items-center justify-center bg-theme-blue border-t pb-6'>
      <div className='container flex-col items-center justify-center ml-auto mr-auto'>
        <div className=' flex flex-col sm:flex-row mt-8 justify-around lg:ml-48'>
          <div className='flex-col ml-10 mr-8 text-center px-4 lg:px-0'>
            <BrandIcon />
            <h1 className='text-lg sm:text-3xl text-theme-white font-bold leading-tight  mr-2 '>
              1 Ten Solutions
            </h1>
            <p className='w-full td sm:text-lg text-theme-white font-light'>
              <b>DESIGN</b> | <b>DEVELOP</b> | <b>DEPLOY</b>
            </p>
          </div>
          <div className='flex flex-col items-center md:items-start text-center lg:text-left lg:mr-20 lg:ml-60 '>
            <h1 className='text-lg text-theme-white pt-2 pb-2 '>Menu</h1>
            <Button
              href='/about'
              type='link'
              className='flex items-center text-lg text-theme-white font-light no-underline hover:text-blue-400'
              isExternal
            >
              {" "}
              About Us
            </Button>
            <Button
              href='/solutions'
              type='link'
              className='flex items-center text-lg text-theme-white font-light no-underline hover:text-blue-400'
              isExternal
            >
              Solutions
            </Button>
            <Button
              href='/services'
              type='link'
              className='flex items-center text-lg text-theme-white font-light no-underline hover:text-blue-400'
              isExternal
            >
              Services
            </Button>
            <Button
              href='/portfolio'
              type='link'
              className='flex items-center text-lg text-theme-white font-light no-underline hover:text-blue-400'
              isExternal
            >
              {" "}
              Portfolio
            </Button>
            <Button
              href='/contact'
              type='link'
              className='flex items-center text-lg text-theme-white font-light no-underline hover:text-blue-400'
              isExternal
            >
              {" "}
              Contact Us
            </Button>
          </div>

          <div className='flex flex-col items-center md:items-start text-center lg:text-left sm:mx-auto lg:mr-40'>
            <h1 className='text-lg text-theme-white pt-2 pb-2 pl-1'>Links</h1>

            <Button
              href='/TnC'
              type='link'
              className='flex items-center text-lg text-theme-white font-light no-underline hover:text-blue-400'
              isExternal
            >
              Terms & Conditions
            </Button>
            <Button
              href='/privacypolicy'
              type='link'
              className='flex items-center text-lg text-theme-white font-light lg:ml-0 no-underline  hover:text-blue-400'
              isExternal
            >
              Privacy Policy
            </Button>
            <Button
              href='/disclaimer'
              type='link'
              className='flex items-center text-lg text-theme-white lg:ml-0 font-light no-underline  hover:text-blue-400'
              isExternal
            >
              {" "}
              Disclaimer
            </Button>
            <div className='flex flex-row mt-4 lg:mt-0 flex-nowrap'>
            <Button
              href='https://www.linkedin.com/company/1tensolutions'
              className='flex-col items-center text-lg text-theme-white font-light hover:underline '
              type='link'
              target='_blank'
              isExternal
            >
              <LinkedInIcon
                style={{ fill: "white", width: "25", height: "25" }}
              />
            </Button>

            <Button
              href='https://www.facebook.com/1Tensolutions'
              className='flex-col items-center text-lg text-theme-white font-light hover:underline'
              type='link'
              target='_blank'
              isExternal
            >
              <FacebookIcon
                style={{ fill: "white", width: "25", height: "25" }}
              />
            </Button>

            <Button
              href='https://www.instagram.com/1tensolutions/'
              className='flex-col items-center text-lg text-theme-white font-light hover:underline'
              type='link'
              target='_blank'
              isExternal
            >
              <InstagramIcon
                style={{ fill: "white", width: "25", height: "25" }}
              />
            </Button>

            <Button
              href='https://twitter.com/1tensolutions'
              className='flex-col items-center text-lg text-theme-white font-light hover:underline pt-1.5'
              type='link'
              target='_blank'
              isExternal
            >
              <img src={twitterXlogo} alt="twitter" className="w-5" />
            </Button>
          </div>
          </div>
        </div>
        <div className='lg:flex lg:flex-row text-center mt-10 justify-end'>
          <p className='flex flex-row text-sm sm:text-lg text-theme-white text-center justify-center mx-auto font-light mt--4 '>
            &copy; 2025 - 1 Ten Solutions All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
